export const baseURL = 'https://node-metahorse.mobiloitte.com' //stagingURL

const url = `${baseURL}/api/v1`
const nft = `${url}/nft`
const order = `${url}/order`
const admin = `${url}/admin`
const static1 = `${url}/static`
const whiteList = `${url}/whiteList`

export const websiteName =
  window.location.protocol + '//' + window.location.host

const Apiconfigs = {
  login: `${url}/admin/login`,

  //nft
  ipfsUpload: `${nft}/ipfsUpload`,
  uploadNFT: `${nft}/uploadNFT`,
  createNft: `${nft}/createNft`,
  listNft: `${nft}/listNftWithoutMarketPlace`,
  viewNft: `${nft}/viewNft`,

  forgotPassword: `${url}/admin/forgotPassword`,
  otpVerify: `${url}/admin/otpVerify`,
  resendOtp: `${url}/admin/resendOtp`,

  // profile
  profile: `${url}/admin/profile`,
  updateProfile: `${url}/admin/updateProfile`,

  //horse
  horse: `${url}/horse/horse`,
  listHorse: `${url}/horse/listHorse`,
  activeBlockHorse: `${url}/horse/activeBlockHorse`,
  updateHorseEventWinner: `${url}/admin/updateHorseEventWinner`,

  //vaccination
  vaccination: `${url}/horse/vaccination`,
  listVaccination: `${url}/horse/listVaccination`,

  // reset
  resetPassword: `${url}/admin/resetPassword`,
  addCategory: `${url}/category/addCategory`,
  listCategory: `${url}/category/listCategory`,
  activeBlockCategory: `${url}/category/activeBlockCategory`,
  deleteCategory: `${url}/category/deleteCategory`,
  viewCategory: `${url}/category/viewCategory`,

  //Collection
  addCollection: `${url}/collection/collection`,
  listCollection: `${url}/collection/listCollection`,

  //order
  putOnsale: `${order}/putOnsale`,
  listOrder: `${order}/listOrder`,
  activeBlockOrder: `${order}/activeBlockOrder`,
  viewOrder: `${order}/viewOrder`,
  cancelOrder: `${order}/cancelOrderAdmin`,

  //event
  createEvent: `${admin}/createEvent`,
  eventList: `${admin}/eventListUpcoming`,
  activeBlockEvent: `${admin}/activeBlockEvent`,
  deleteEvent: `${admin}/deleteEvent`,
  viewEvent: `${admin}/viewEvent`,
  updateEvent: `${admin}/updateEvent`,
  eventListPast: `${admin}/eventListPast`,
  expiredEvent: `${admin}/expiredEvent`,

  //SUBADMIN
  addSubamin: `${admin}/addSubamin`,
  listUser: `${admin}/listUser`,
  viewUser: `${admin}/viewUser`,
  deleteSubadmin: `${admin}/deleteSubadmin`,
  reSendPasswordForsubAdmin: `${admin}/reSendPasswordForsubAdmin`,

  //STATIC
  StaticContent: `${static1}/StaticContent`,
  staticContentList: `${static1}/StaticContentList`,

  //transaction
  transactionList: `${admin}/transactionList`,

  //WHITELIST
  listRequestedWhiteList: `${whiteList}/listRequestedWhiteList`,
  viewWhitlist: `${whiteList}/viewWhitlist`,
  acceptWhitelistRequest: `${url}/admin/acceptWhitelistRequest`,
  rejectWhitelistRequest: `${url}/admin/rejectWhitelistRequest`,
  acceptRejectWhitelistRequest: `${url}/admin/acceptRejectWhitelistRequest`,

  //dashboard
  dashboard: `${url}/admin/dashboard`,
  //user
  activeBlockUser: `${admin}/activeBlockUser`,

  //achievements
  addAchievement: `${admin}/addAchievement`,
  listAchievement: `${admin}/listAchievement`,
  viewAchievement: `${admin}/viewAchievement`,
  updateAchievement: `${admin}/updateAchievement`,


}

export default Apiconfigs
