import React, { createContext, useState, useEffect } from 'react'
import axios from 'axios'
import Apiconfigs from 'src/ApiConfig/ApiConfig'
import { injected } from 'src/connectors'
import { ACTIVE_NETWORK } from 'src/constants'
import { useWeb3React } from '@web3-react/core'
import { toast } from 'react-toastify'
import { getWeb3Obj } from 'src/utils/index'
export const AuthContext = createContext()

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('creatturAccessToken', accessToken)
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  } else {
    localStorage.removeItem('creatturAccessToken')
    delete axios.defaults.headers.common.Authorization
  }
}

function checkLogin() {
  const accessToken = window.sessionStorage.getItem('token')
  return accessToken ? true : false
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin())
  // const [userData] = useState({});
  const [yourWalletBalance, setYourWalletBalance] = useState(0)
  const { activate, account, chainId } = useWeb3React()
  const [userData, setUserData] = useState({})
  const connectToWallet = (data) => {
    const connector = injected
    window.sessionStorage.removeItem('walletName')
    window.sessionStorage.setItem('walletName', 'METAMASK')
    // setErrorMsg("");
    // setSuccessMSG("");
    if (connector && connector.walletConnectProvider?.wc?.uri) {
      connector.walletConnectProvider = undefined
    }
    activate(connector, undefined, true).catch((error) => {
      if (error) {
        console.log('error', error.message)
        // setErrorMsg(error.message + " Please install " + data.name);
        activate(connector)
        // setIsLoading(false);
        // setErrorPop(true);
      }
    })
  }
  useEffect(() => {
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler()
        }
      }
    }
  }, [chainId, account])
  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x' + ACTIVE_NETWORK.toString(16) }],
      })
    } catch (error) {
      console.log('ERROR', error)

      toast.warn(error.message)
      // if (error.code === 4902) {
      //   addNetworkHandler();
      // }
    }
  }
  useEffect(() => {
    if (window.sessionStorage.getItem('walletName')) {
      connectToWallet()
    }
  }, [window.sessionStorage.getItem('walletName')])

  let data = {
    userLoggedIn: isLogin,
    userData,
    yourWalletBalance,
    profileHandler: (data) => profileHandler(data),
    connectToWallet: () => connectToWallet(),
    setIsLogin: (data) => setIsLogin(data),
    userLogIn: (type, data) => {
      setSession(data)
      setIsLogin(type)
    },
  }
  const profileHandler = async (token) => {
    try {
      const res = await axios({
        method: 'GET',
        url: Apiconfigs.profile,
        headers: {
          token: token,
        },
      })
      if (res.data.responseCode === 200) {
        setUserData(res.data.result)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getUserbalce = async () => {
    const web3 = await getWeb3Obj()
    const balance = await web3.eth.getBalance(account)
    const balanceImETH = await web3.utils.fromWei(balance)
    setYourWalletBalance(parseFloat(balanceImETH).toFixed(2))
  }
  useEffect(() => {
    if (account) {

      getUserbalce()
    }
  }, [account])
  useEffect(() => {
    const accessToken = window.sessionStorage.getItem('token')
    if (accessToken) {
      profileHandler(accessToken)
    }
  }, [window.sessionStorage.getItem('token')])

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  )
}
