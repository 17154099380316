import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
import Loginlayout from "src/layouts/Loginlayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/login",
    layout: Loginlayout,
    component: lazy(() => import("src/views/auth/Login")),
  },
  {
    exact: true,
    path: "/forget-password",
    layout: Loginlayout,

    component: lazy(() => import("src/views/auth/forget-password")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: Loginlayout,

    component: lazy(() => import("src/views/auth/Verify-Otp/VerifyOtp")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: Loginlayout,

    component: lazy(() => import("src/views/auth/reset-password")),
  },
  {
    exact: true,
    path: "/create",
    guard: true,
    layout: HomeLayout,
    component: lazy(() => import("src/Create-NFT/CreateNFT")),
  },
  {
    exact: true,
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },

  {
    exact: true,
    path: "/active-horses",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/NFT/ActiveHorses")),
  },
  {
    exact: true,
    path: "/vacination-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/VaccinationList")),
  },
  {
    exact: true,
    path: "/achievement-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Achievents/AchieventsList")),
  },
  {
    exact: true,
    path: "/add-subadmin",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/subadmin/AddSubadmin")),
  },

  {
    exact: true,
    path: "/faq",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/staticManagement/Faq")),
  },

  {
    exact: true,
    path: "/edit",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/staticManagement/Edit")),
  },
  {
    exact: true,
    path: "/delete",
    guard: true,
    // layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/staticManagement/Delete")),
  },

  {
    exact: true,
    path: "/add-horse",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/AddHorse")),
  },
  {
    exact: true,
    path: "/my-account",
    guard: true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/MyAccount/index")),
  },
  // {
  //   exact: true,
  //   path: "/faveorites",
  //   guard: true,
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/Faveorites/index")),
  // },
  {
    exact: true,
    path: "/edit-profile",
    guard: true,
    // layout: HomeLayout,
    component: lazy(() => import("src/Profile/EditProfile")),
  },
  {
    exact: true,
    path: "/dialog",
    guard: true,
    // layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Dashboard/DialogUpdate")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    exact: true,
    path: "/privacy",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/staticManagement/PrivacyPolicy")
    ),
  },
  {
    exact: true,
    path: "/term-conditions",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/staticManagement/TermsConditions")
    ),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
