import React from 'react'
import {
  Grid,
  Box,
  Container,
  Typography,
  List,
  ListItem,
  makeStyles,
  Button,
  Divider,
  TextField,
  IconButton,
  Link,
} from '@material-ui/core'
import TwitterIcon from '@material-ui/icons/Twitter'
import TelegramIcon from '@material-ui/icons/Telegram'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import YouTubeIcon from '@material-ui/icons/YouTube'
import { SiWechat, SiDiscord } from 'react-icons/si'
import { AiFillWechat } from 'react-icons/ai'
import { Link as RouterLink } from 'react-router-dom'
import Logo from './../../component/Logo'

const useStyles = makeStyles((theme) => ({
  footerSection: {
    position: 'relative',
    zIndex: '9',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(7),
      position: 'relative',
      zIndex: '9',
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(7),
      position: 'relative',
      zIndex: '9',
    },
  },
  borderBottmo: {
    overflow: 'hidden',
    background: '#000',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },

  largeIcon: {
    width: 18,
    height: 18,
    marginRight: '8px',
  },
  icons: {
    justify: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justify: 'center',
    },
  },
  button: {
    backgroundColor: '#fab758',
    color: '#ffffff',
    border: '2px solid #fab758',
    borderRadius: '40px',
    minHeight: '50px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    lineHeight: '1.5',
    fontWeight: '500',
    transition: '0.26s ease',
    minWidth: '160px',
    paddingBottom: '3px',
    textTransform: 'capitalize',
    marginRight: '15px',
    marginBottom: '10px',
    marginTop: '27px',
  },
  join: {
    fontFamily: "'Impact'",
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '35px',
    lineHeight: '46px',
    color: '#fff',
    [theme.breakpoints.down('md')]: {
      fontSize: '33px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
  },
  join1: {
    fontFamily: "'Inter'",
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#B7B7B7',
  },
  textFild: {
    position: 'relative',

    '& button': {
      position: 'absolute',
      top: 0,
      right: 0,
      height: '100%',
      backgroundColor: '#FAB758',
      minWidth: '90px',
      fontSize: '16px',
      fontWeight: '500',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#000',
      },
    },
  },
  box: {
    display: 'flex',
  },
  btn: {
    width: '118px',
    height: '52px',
    marginLeft: '1rem',
    background: '#713FFF',
    borderRadius: '5px',
    color: 'white',
  },
  dot: {
    height: '35px',
    width: '35px',
    borderRadius: '50%',
    backgroundColor: '#713FFF',
    // marginLeft: "16px",
    cursor: 'pointer',
    // "@media(max-width:420px)": {
    //   height: "30px",
    //   width: "30px",
    // },
  },
  dot1: {
    height: '35px',
    width: '35px',
    borderRadius: '50%',
    backgroundColor: '#713FFF',
    // marginLeft: "1rem",
    cursor: 'pointer',
    '@media(max-width:420px)': {
      // height: "30px",
      // width: "30px",
      marginLeft: '0px',
    },
  },
  market: {
    fontFamily: "'Impact'",
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '0.05em',
    color: '#fff',
  },
  text: {
    backgroundColor: '#32236D',
    color: 'white',
    borderRadius: '5px',
  },
  grid2: {
    display: 'flex',
    justifyContent: 'end',
    flexDirection: 'column',
    textAlign: 'end',
    '@media(max-width:960px)': {
      display: 'flex',
      justifyContent: 'flex-start',
      textAlign: 'start',
      marginTop: '2rem',
    },
  },
  // list: {
  //   display: "flex",
  //   // justifyContent: "end",
  //   "@media(max-width:958px)": {
  //     display: "flex",
  //     justifyContent: "flex-start",
  //   },
  // },
  baseSection: {
    display: 'flex',
    justifyContent: 'space-between',
    '& p': {
      color: '#B7B7B7',
      fontSize: '16px',
    },
    '@media(max-width:565px)': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  privacy: {
    display: 'flex',
    '@media(max-width:565px)': {
      marginTop: '1.5rem',
    },
  },
  icon: {
    margin: '6px 6px',
    color: 'white',
    cursor: 'pointer',
    // "@media(max-width:420px)": {
    //   margin: "4px 4px",
    // },
  },
  icon1: {
    margin: '9px 9px',
    color: 'white',
    cursor: 'pointer',
    // "@media(max-width:420px)": {
    //   margin: "8px 8px",
    // },
  },
  listitem: {
    fontFamily: "'Inter'",
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#B7B7B7',
    cursor: 'pointer',
  },
  llc: {
    fontFamily: "'Inter'",
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#B7B7B7',
  },
  privacy1: {
    fontFamily: "'Inter'",
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#B7B7B7',
    cursor: 'pointer',
  },
  gridicon: {
    display: 'flex',
    justifyContent: 'flex-end',
    '@media(max-width:959px)': {
      display: 'flex',
      justifyContent: 'flex-start',
    },
  },
}))

export default function Liquidity() {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.footerSection}>
        <Box style={{ margin: '20px 10px 0' }}>
          <Container maxWidth="lg">


            <Box className={classes.baseSection}>
              <Typography variant="body1">@ 2022 OmniHorse LLC</Typography>
              <Box className={classes.privacy}>
                <RouterLink to="/privacy">
                  <Typography variant="body1">Privacy Policy </Typography>
                </RouterLink>
                &nbsp; &nbsp; &nbsp;
                <RouterLink to="/term-conditions">
                  {' '}
                  <Typography variant="body1">Terms & Conditions</Typography>
                </RouterLink>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  )
}
